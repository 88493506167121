import React, { Component } from 'react'
import { get } from '../../ajax/tools'

class MemberJump extends Component {
  constructor(props) {
    super(props)
    this.state = {
      GroupArr: [
        {
          id: 1,
          nav: '基础信息',
          router: '/member_grBase',
          navNext: '',
        },
        {
          id: 2,
          nav: '能力测试',
          router: '/exam_r',
          navNext: '',
        },
        {
          id: 3,
          nav: '申请服务',
          router: '/approve_s',
          navNext: '',
        },
        {
          id: 4,
          nav: '赛事活动',
          router: '/member_grGame',
          navNext: '',
        },
        {
          id: 5,
          nav: '推荐辅导员',
          router: '/member_grTJF',
          navNext: '',
        },
        // {
        //     id:6,
        //     nav:'云程数据',
        //     router:'/member_gryc',
        //     navNext:'',
        // },
        {
          id: 16,
          nav: '服务中心',
          router: '/member_service',
          navNext: '',
        },
        {
          id: 7,
          nav: '初教六激活',
          router: '/member_grsix',
          navNext: '',
        },
      ],
      PersonalArr: [
        {
          id: 1,
          nav: '基础信息',
          router: '/member_peBase',
          navNext: '',
        },
        {
          id: 2,
          nav: '个人能力',
          router: '/member_pestu',
          navNext: '>技术等级',
        },
        {
          id: 3,
          nav: '能力测试',
          router: '/exam_p',
          navNext: '',
        },
        {
          id: 4,
          nav: '飞行团队',
          router: '/member_peteam',
          navNext: '>竞技团队',
        },
        // {
        //     id:5,
        //     nav:'训练数据',
        //     router:'/member_peda',
        //     navNext:'',
        // },
        {
          id: 6,
          nav: '组织管理',
          router: '/member_pezz',
          navNext: '',
        },
        {
          id: 7,
          nav: '云程服务',
          router: '/member_peyc',
          navNext: '',
        },
        // {
        //     id:8,
        //     nav:'VIP服务',
        //     router:'/member_pevip',
        //     navNext:'',
        // },
        {
          id: 9,
          nav: '初教六激活',
          router: '/member_pesix',
          navNext: '',
        },
        {
          id: 99,
          nav: '领取证书',
          router: '/member_Prize',
          navNext: '',
        },
      ],
    }
  }
  componentDidMount = function () {
    let Route = this.props.location.pathname
    let ThreeIndex = Route.lastIndexOf('_') //'_'最后出现的位置
    // console.log(Route.substring(ThreeIndex+1));
    if (ThreeIndex === -1) {
      get({ url: 'user/basic_info' }).then((res) => {
        if (res.errno === '200') {
          let User = res.data[0]
          if (User.type === 1) {
            let GroupArr = this.state.GroupArr
            let a = {
              id: 8,
              nav: '训练中心档案',
              router: '/member_grtr',
              navNext: '>机构信息',
            }
            let b = {
              id: 9,
              nav: '青少年航校',
              router: '/member_grschool',
              navNext: '>航校信息',
            }
            let c = {
              id: 10,
              nav: '实训基地',
              router: '/member_grtrba',
              navNext: '>基地信息',
            }
            let d = {
              id: 11,
              nav: '设备制造商档案',
              router: '/member_grdiv',
              navNext: '>设备信息',
            }
            let e = {
              id: 12,
              nav: '荣誉墙',
              router: '/member_grryq',
              navNext: '',
            }
            if (User.isinstitutions !== 0) {
              GroupArr.splice(1, 0, a)
              if (User.isyoung_school !== 0) {
                GroupArr.splice(2, 0, b)
                if (User.istrain_base !== 0) {
                  GroupArr.splice(3, 0, c)
                  if (User.isdivice !== 0) {
                    GroupArr.splice(4, 0, d)
                  }
                } else {
                  if (User.isdivice !== 0) {
                    GroupArr.splice(3, 0, d)
                  }
                }
              } else {
                if (User.istrain_base !== 0) {
                  GroupArr.splice(2, 0, c)
                  if (User.isdivice !== 0) {
                    GroupArr.splice(3, 0, d)
                  }
                } else {
                  if (User.isdivice !== 0) {
                    GroupArr.splice(2, 0, d)
                  }
                }
              }
            } else {
              if (User.isyoung_school !== 0) {
                GroupArr.splice(1, 0, b)
                if (User.istrain_base !== 0) {
                  GroupArr.splice(2, 0, c)
                  if (User.isdivice !== 0) {
                    GroupArr.splice(3, 0, d)
                  }
                } else {
                  if (User.isdivice !== 0) {
                    GroupArr.splice(2, 0, d)
                  }
                }
              } else {
                if (User.istrain_base !== 0) {
                  GroupArr.splice(1, 0, c)
                  if (User.isdivice !== 0) {
                    GroupArr.splice(2, 0, d)
                  }
                } else {
                  if (User.isdivice !== 0) {
                    GroupArr.splice(1, 0, d)
                  }
                }
              }
            }
            get({ url: 'user/get_honor_diploma' }).then((res) => {
              if (res.errno === '200' && res.data.length !== 0) {
                GroupArr.push(e)
                sessionStorage.setItem('GroupArr', JSON.stringify(GroupArr))
                const { history } = this.props
                history.push('/member_grBase')
              } else {
                sessionStorage.setItem('GroupArr', JSON.stringify(GroupArr))
                const { history } = this.props
                history.push('/member_grBase')
              }
            })
          } else if (User.type === 0) {
            get({ url: 'passport/old_check' }).then((res) => {
              if (res.errno === '200') {
                const { history } = this.props
                history.push('/real_name')
              } else {
                let PersonalArr = this.state.PersonalArr
                let a = {
                  id: 10,
                  nav: '教员信息',
                  router: '/member_petea',
                  navNext: '>考核信息',
                }
                let b = {
                  id: 11,
                  nav: '辅导员信息',
                  router: '/member_pecou',
                  navNext: '',
                }
                if (User.iscoach !== -1) {
                  PersonalArr.splice(2, 0, a)
                  if (User.isassist !== 0) {
                    PersonalArr.splice(3, 0, b)
                  }
                } else {
                  if (User.isassist !== 0) {
                    PersonalArr.splice(2, 0, b)
                  }
                }
                sessionStorage.setItem(
                  'PersonalArr',
                  JSON.stringify(PersonalArr)
                )
                const { history } = this.props
                history.push('/member_peBase')
              }
            })
          }
        } else {
          const { history } = this.props
          history.push('/login')
        }
      })
    } else {
      console.log(ThreeIndex)
    }
  }

  render() {
    return <div> </div>
  }
}

export default MemberJump
