import React, { Component } from 'react'
import PopUp from '../round/PopUp'
import './member.css'
import { get, post } from '../../ajax/tools'

class PeStudent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: {},
      MemberTopNav: '技术等级',
      TopNavArr: ['技术等级', '个人荣誉'],
      NavSelected: {
        backgroundColor: '#0488CA',
        color: '#FFFFFF',
        borderLeftColor: '#000000',
        borderTopColor: '#000000',
        borderRightColor: '#000000',
      },
      NoSelected: {
        borderBottomColor: '#000000',
      },
      ExamInfoArr: [],
      GameInfoArr: [],
    }
  }

  componentDidMount = function () {
    get({ url: 'user/stu_exam_info' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          ExamInfoArr: res.data,
        })
      } else {
        this.setState({
          ExamInfoArr: [],
        })
      }
    })
    get({ url: 'user/stu_match_info' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          GameInfoArr: res.data,
        })
      } else {
        this.setState({
          GameInfoArr: [],
        })
      }
    })
  }

  //顶部菜单选中
  MemberTopNav = (item, index) => {
    if (item === '技术等级') {
      get({ url: 'user/stu_exam_info' }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            ExamInfoArr: res.data,
          })
        } else {
          this.setState({
            ExamInfoArr: [],
          })
        }
      })
      this.props.ChildBackNavClick('技术等级')
    } else if (item === '个人荣誉') {
      get({ url: 'user/stu_match_info' }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            GameInfoArr: res.data,
          })
        } else {
          this.setState({
            GameInfoArr: [],
          })
        }
      })
      this.props.ChildBackNavClick('个人荣誉')
    }
    this.setState({
      MemberTopNav: item,
    })
  }

  //点击信息中的证书选项
  PeStudentView = (item, index) => {
    post({
      url: 'user/show_diploma_ord',
      data: {
        type: 0,
        level: item.level,
      },
    }).then((res) => {
      if (res.errno === '200') {
        let diplomaCode = res.data[0].diploma_code
        window.open('/certificate/' + diplomaCode)
      } else {
        this.setState({
          PopUp: '提示弹窗',
          MessageTip: '解析证书失败，请稍后重试！',
        })
      }
    })
  }

  //点击学员的教练员查看详细信息
  CoachDetails = (item, index) => {
    if (item.coach_id !== '') {
      this.props.BackCoachDetails({ coach_id: item.coach_id })
    }
  }

  //点击学员的教练员查看详细信息
  AwardCertificate = (item, index) => {
    if (item.isprize_certif === 1 && item.prize_certif !== '') {
      window.open('/award/' + item.prize_certif.substring(34))
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '电子证书暂未发放！',
      })
    }
  }
  AwardCertificateRank = (item, index) => {
    if (item.isrank_certif === 1 && item.rank_certif !== '') {
      window.open('/award/' + item.rank_certif.substring(34))
    } else {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '电子证书暂未发放！',
      })
    }
  }

  //提示弹窗
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      <div
        className={'MemberPackageBox'}
        style={{ height: window.innerHeight - 180 }}
      >
        <div className={'MemberTopNavBox'}>
          {this.state.TopNavArr.map((item, index) => (
            <div
              className={'MemberTopNav'}
              key={index}
              onClick={() => this.MemberTopNav(item, index)}
              style={
                item === this.state.MemberTopNav
                  ? this.state.NavSelected
                  : this.state.NoSelected
              }
            >
              {item}
            </div>
          ))}
          <div
            className={'MemberPosition'}
            style={{
              width: 636,
              height: 61,
              borderBottom: '1px solid #000000',
            }}
          >
            {' '}
          </div>
        </div>
        {this.state.MemberTopNav === '技术等级' && (
          <div
            className={'PeStudentInfoBox'}
            style={{ height: window.innerHeight - 270 }}
          >
            <div className={'TableHeader'}>
              <div className={'TableHeaderInfo'} style={{ width: 200 }}>
                时间
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 258 }}>
                地点
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 150 }}>
                等级
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 160 }}>
                结果（证书）
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 150 }}>
                教练员
              </div>
            </div>
            <div
              className={'TableContentBox'}
              style={{ height: window.innerHeight - 338 }}
            >
              {this.state.ExamInfoArr.map((item, index) => (
                <div className={'TableContent'} key={index}>
                  <div className={'TableContentInfo'} style={{ width: 200 }}>
                    {item.ctime.substring(0, 10)}
                  </div>
                  <div className={'TableContentInfo'} style={{ width: 258 }}>
                    {item.address}
                  </div>
                  <div className={'TableContentInfo'} style={{ width: 150 }}>
                    {item.name}
                  </div>
                  {item.isvalid === 0 && (
                    <div
                      className={'TableContentInfo'}
                      style={{ width: 160, color: '#BB0000' }}
                    >
                      未通过
                    </div>
                  )}
                  {item.isvalid === 1 && (
                    <div
                      className={'TableContentInfo'}
                      style={{ width: 160, color: '#787878' }}
                    >
                      未测试
                    </div>
                  )}
                  {item.isvalid === 2 && (
                    <div className={'TableContentInfo'} style={{ width: 160 }}>
                      <div
                        className={'TableContentInfoWord'}
                        title={'查看证书'}
                        onClick={() => this.PeStudentView(item, index)}
                      >
                        <div
                          style={{ float: 'left' }}
                          className={'ClickDetails'}
                        >
                          通过(查看证书)
                        </div>
                        <div className={'ViewIco'}> </div>
                      </div>
                    </div>
                  )}
                  <div className={'TableContentInfo'} style={{ width: 150 }}>
                    <span
                      className={'ClickDetails'}
                      onClick={() => this.CoachDetails(item, index)}
                    >
                      {item.real_name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.MemberTopNav === '个人荣誉' && (
          <div
            className={'PeStudentInfoBox'}
            style={{ height: window.innerHeight - 270 }}
          >
            <div className={'TableHeader'}>
              <div className={'TableHeaderInfo'} style={{ width: 280 }}>
                名称
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 180 }}>
                项目
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 120 }}>
                名次
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 136 }}>
                成绩
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 200 }}>
                获奖状态
              </div>
            </div>
            <div
              className={'TableContentBox'}
              style={{ height: window.innerHeight - 338 }}
            >
              {this.state.GameInfoArr.map((item, index) => (
                <div className={'TableContent'} key={index}>
                  <div
                    className={'TableContentInfo'}
                    title={item.match_name}
                    style={{ width: 280 }}
                  >
                    {item.match_name}
                  </div>
                  <div className={'TableContentInfo'} style={{ width: 180 }}>
                    {item.item_name}
                  </div>
                  {item.isrank_certif !== 1 && (
                    <div className={'TableContentInfo'} style={{ width: 120 }}>
                      {item.rank}
                    </div>
                  )}
                  {item.isrank_certif === 1 && (
                    <div className={'TableContentInfo'} style={{ width: 120 }}>
                      <div
                        className={'TableContentInfoWord'}
                        title={'查看证书'}
                        onClick={() => this.AwardCertificateRank(item, index)}
                      >
                        <div
                          style={{ float: 'left', maxWidth: 96 }}
                          className={'ClickDetails Ellipsis'}
                        >
                          {item.rank}(获奖证书)
                        </div>
                        <div className={'ViewIco'}> </div>
                      </div>
                    </div>
                  )}
                  <div className={'TableContentInfo'} style={{ width: 136 }}>
                    {item.total_score}
                  </div>
                  {item.isprize_certif === 1 && (
                    <div className={'TableContentInfo'} style={{ width: 200 }}>
                      <div
                        className={'TableContentInfoWord'}
                        title={'查看证书'}
                        onClick={() => this.AwardCertificate(item, index)}
                      >
                        <div
                          style={{ float: 'left' }}
                          className={'ClickDetails'}
                        >
                          {item.prize} (获奖证书)
                        </div>
                        <div className={'ViewIco'}> </div>
                      </div>
                    </div>
                  )}
                  {item.isprize_certif !== 1 && item.prize !== '' && (
                    <div className={'TableContentInfo'} style={{ width: 200 }}>
                      {item.prize === '未获奖' && (
                        <span style={{ color: '#3d3d3d' }}>{item.prize}</span>
                      )}
                      {item.prize !== '未获奖' && (
                        <span style={{ color: '#FD7B4D' }}>{item.prize}</span>
                      )}
                    </div>
                  )}
                  {item.isprize_certif !== 1 && item.prize === '' && (
                    <div className={'TableContentInfo'} style={{ width: 200 }}>
                      待公布
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.PopUp === '提示弹窗' && (
          <PopUp
            ChildBackClick={this.ChildBackTip.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default PeStudent
