import React, { Component } from 'react'
import './member.css'
import { get } from '../../ajax/tools'

class PeCounselor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: {},
      MemberTopNav: '辅导员信息',
      TopNavArr: ['辅导员信息'],
      NavSelected: {
        backgroundColor: '#0488CA',
        color: '#FFFFFF',
        borderLeftColor: '#000000',
        borderTopColor: '#000000',
        borderRightColor: '#000000',
      },
      NoSelected: {
        borderBottomColor: '#000000',
      },
      ExamInfoArr: [],
    }
  }
  componentDidMount = function () {
    get({ url: 'user/assist_recome_info' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          ExamInfoArr: res.data,
        })
      } else {
        this.setState({
          ExamInfoArr: [],
        })
      }
    })
  }

  //顶部菜单选中
  MemberTopNav = (item, index) => {
    if (item === '辅导员信息') {
      this.props.ChildBackNavClick('辅导员信息')
    }
    this.setState({
      MemberTopNav: item,
    })
  }

  //点击信息中的证书选项
  PeCounselor = (item, index) => {
    window.open('/certificate/' + item.diploma_code)
  }

  // 点击开始笔试答题
  StartAsk = (item, index) => {
    window.open('/ask/' + item.id)
  }

  render() {
    return (
      <div
        className={'MemberPackageBox'}
        style={{ height: window.innerHeight - 180 }}
      >
        <div className={'MemberTopNavBox'}>
          {this.state.TopNavArr.map((item, index) => (
            <div
              className={'MemberTopNav'}
              key={index}
              onClick={() => this.MemberTopNav(item, index)}
              style={
                item === this.state.MemberTopNav
                  ? this.state.NavSelected
                  : this.state.NoSelected
              }
            >
              {item}
            </div>
          ))}
          <div
            className={'MemberPosition'}
            style={{
              width: 808,
              height: 61,
              borderBottom: '1px solid #000000',
            }}
          >
            {' '}
          </div>
        </div>
        {this.state.MemberTopNav === '辅导员信息' && (
          <div
            className={'PeStudentInfoBox'}
            style={{ height: window.innerHeight - 270 }}
          >
            <div className={'TableHeader'}>
              <div className={'TableHeaderInfo'} style={{ width: 320 }}>
                推荐单位
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 166 }}>
                推荐时间
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 210 }}>
                能力测试
              </div>
              <div className={'TableHeaderInfo'} style={{ width: 220 }}>
                状态
              </div>
            </div>
            <div
              className={'TableContentBox'}
              style={{ height: window.innerHeight - 338 }}
            >
              {this.state.ExamInfoArr.map((item, index) => (
                <div className={'TableContent'} key={index}>
                  <div className={'TableContentInfo'} style={{ width: 320 }}>
                    {item.org_name}
                  </div>
                  <div className={'TableContentInfo'} style={{ width: 166 }}>
                    {item.up_time.substring(0, 10)}
                  </div>
                  {item.ispass_write === 0 && (
                    <div className={'TableContentInfo'} style={{ width: 210 }}>
                      <div
                        className={'TableContentInfoWord'}
                        title={'查看证书'}
                        onClick={() => this.StartAsk(item, index)}
                      >
                        <div className={'ClickDetails'}>开始测试</div>
                      </div>
                    </div>
                  )}
                  {item.ispass_write !== 0 && (
                    <div className={'TableContentInfo'} style={{ width: 210 }}>
                      {item.iswrite_score}
                    </div>
                  )}
                  {item.status === 0 && item.diploma_code === '' && (
                    <div className={'TableContentInfo'} style={{ width: 220 }}>
                      审核中···
                    </div>
                  )}
                  {item.status !== 0 && item.diploma_code !== '' && (
                    <div className={'TableContentInfo'} style={{ width: 220 }}>
                      <div
                        className={'TableContentInfoWord'}
                        title={'查看证书'}
                        onClick={() => this.PeCounselor(item, index)}
                      >
                        <div
                          style={{ float: 'left' }}
                          className={'ClickDetails'}
                        >
                          通过(查看证书)
                        </div>
                        <div className={'ViewIco'}> </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default PeCounselor
